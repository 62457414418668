import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { renderTable } from '../shared/TableHelper'
import { Link, Button } from '@material-ui/core'
import DialogContainer from '../DialogContainer'
import CardTitle from '../shared/CardTitle'
import Alert from '@material-ui/lab/Alert'

const classes = makeStyles(theme => ({}))

let defaultContainers = [
    { key: 'containerNumber', label: 'Container #', icon: false, link: true },
    {
        key: 'containerTrackingLink',
        label: 'Tracking',
        icon: false,
        link: true
    },
    {
        key: 'openTrackLink',
        label: 'OpenTrack',
        icon: false,
        link: true
    },
    { key: 'sealNumber', label: 'Seal #', icon: false },
    { key: 'importReferenceNumber', label: 'Import Ref #', icon: false },
    { key: 'totalPacks', label: 'Packs', icon: false },
    { key: 'totalPacksUOM', label: 'Packs Type', icon: false },
    { key: 'containerType', label: 'Container Type', icon: false },
    { key: 'containerMode', label: 'Container Mode', icon: false },
    { key: 'tareWeight', label: 'Tare Weight', icon: false },
    { key: 'weight', label: 'Total Weight', icon: false },
    { key: 'deliveryMode', label: 'Delivery Mode', icon: false },
    { key: 'originGateIn', label: 'Export Gate In', icon: false },
    { key: 'wharfGateOut', label: 'Wharf Gate Out', icon: false },
    { key: 'estimatedDelivery', label: 'Est. Delivery', icon: false },
    { key: 'actualDelivery', label: 'Act. Delivery', icon: false },
    { key: 'estimatedReturn', label: 'Est. Return', icon: false },
    { key: 'actualReturn', label: 'Act. Return', icon: false }
]

const normanHKContainers = [
    { key: 'containerNumber', label: 'Container #', icon: false, link: true },
    {
        key: 'containerTrackingLink',
        label: 'NG Tracking',
        icon: false,
        link: true
    },
    { key: 'sealNumber', label: 'Seal #', icon: false },
    { key: 'containerType', label: 'Container Type', icon: false },
    { key: 'containerMode', label: 'Container Mode', icon: false },
    { key: 'emptyReturnedOn', label: 'Empty Returned On', icon: false },
    { key: 'weight', label: 'Weight', icon: false },
    { key: 'deliveryMode', label: 'Delivery Mode', icon: false }
]

function getContainers(isNormanHK) {
    if (isNormanHK) {
        return normanHKContainers
    } else {
        return defaultContainers
    }
}

const containersReview = [
    { key: 'containerTypeCode', label: 'Container Mode', icon: false },
    { key: 'numberOfContainers', label: 'Number of Containers', icon: false },
    { key: 'containerCommodityCode', label: 'Commodity', icon: false }
]

export default function ShipmentContainersTable({
    itemextra,
    item,
    isReview,
    organization,
    client
}) {

    const [dialogOpen, setDialogOpen] = useState(false)
    const [currentContainer, setCurrentContainer] = useState(null)

    const handleContainerDialogState = () => {
        setDialogOpen(!dialogOpen)
    }

    if (!organization?.containerTrackingURL) {
        // remove tracking column
        defaultContainers = defaultContainers.filter(
            c => c.key !== 'containerTrackingLink'
        )
    }
    if (!organization?.isOpenTrackTracking && !client?.isOpenTrackTracking) {
        // remove open track column
        defaultContainers = defaultContainers.filter(
            c => c.key !== 'openTrackLink'
        )
    }
    else {
        // need to update the open track link label to value from organization
        defaultContainers = defaultContainers.map(c => {
            if (c.key === 'openTrackLink') {
                return {
                    ...c,
                    label: organization?.openTrackButtonTitle || 'OpenTrack'
                }
            }
            return c
        })
    }
    const renderContainerLink = (i, index, arrayKey) => {
        if (
            arrayKey.key === 'containerTrackingLink' &&
            organization.containerTrackingURL
        ) {
            return (
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        const newWindow = window.open(
                            organization.containerTrackingURL.replace(
                                '{CONTAINERNO}',
                                i['containerNumber']
                            ),
                            '_blank',
                            'noopener,noreferrer'
                        )
                        if (newWindow) newWindow.opener = null
                    }}>
                    View
                </Button>
            )
        }

        if (arrayKey.key === 'openTrackLink' && (client.isOpenTrack || organization.isOpenTrack)) {
            return (
                // check if value is null 
                i[arrayKey.key] !== null && i[arrayKey.key] !== '' ? (
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            const newWindow = window.open(
                                i[arrayKey.key],
                                '_blank',
                                'noopener,noreferrer'
                            )
                            if (newWindow) newWindow.opener = null
                        }}>
                        View
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled>
                        View
                    </Button>
                )
            )
        }

        if (arrayKey.key === 'containerNumber') {
            return (
                <div>
                    <Link
                        component="button"
                        className="btn btn-link"
                        variant="body2"
                        style={{ color: 'blue' }}
                        onClick={() => {
                            setCurrentContainer(i)
                            handleContainerDialogState()
                        }}>
                        {' '}
                        {i[arrayKey.key] || '-'}
                    </Link>

                    <DialogContainer
                        dialogCallback={handleContainerDialogState}
                        showDialog={dialogOpen}
                        container={currentContainer}
                        organization={organization}
                        client={client}
                        shipment={item}
                        shipmentExtra={itemextra}
                    />
                </div>
            )
        }
    }

    return (
        <CardTitle title="Containers" checkOn={false} disablePadding={true}>
            {
                itemextra !== null &&
                itemextra !== undefined &&
                itemextra.containers !== null &&
                itemextra.containers !== undefined ? (
                    renderTable(
                        itemextra.containers,
                        isReview
                            ? containersReview
                            : getContainers(
                                organization.shipmentServiceSelected == 'NORMANHK'
                            ),
                        'container',
                        classes,
                        renderContainerLink,
                        {
                            maxHeight: '200px'
                        }
                    )
                ) : (
                    <Alert severity="info" className="w-100">
                        No records found.
                    </Alert>
                )}
        </CardTitle>
    )    
}