import React, { PureComponent } from 'react'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import {
    Grid,
    FormControl,
    Button,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import { TextField, InputAdornment } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import { emailValidation } from '../components/shared/Helpers'
import Alert from '@material-ui/lab/Alert'
import CardTitle from './shared/CardTitle'
import { withStyles } from '@material-ui/core/styles'
import {
    extractCreateShipment,
    extractSearchShipment,
    extractSeeCustoms,
    extractSeeAccounting,
    extractSeeBooking,
    extractSeeOrder,
    extractSeeContainers,
    extractCreateOrder
} from '../utils/permissions'
import { getAppInfo } from '../api/api'
import { CardContent } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    }
})

const usersPath = { pathname: `${ApplicationPaths.Users}` }
const usrRole = 'Member'

class ClientUserDetails extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            clientId: props.match.params.clientId,
            userId:
                props.match.params.userId !== null &&
                props.match.params.userId.toLowerCase() !== 'new'
                    ? props.match.params.userId
                    : null,
            isLoading: false,
            isPosting: false,
            user: [],
            requiredFields: [],
            reportAccessList: [],
            dashboardAccessList: [],
            emailSubscriptionList: [],
            clientUserPermissionsList: [],
            isValidForm: true,
            isValidUserFirstName: true,
            errorTextUserFirstName: '',
            isValidUserLastName: true,
            errorTextUserLastName: '',
            isValidUserEmail: true,
            errorTextUserEmail: '',
            alert: {
                open: false,
                success: false,
                message: ''
            },
            appInfo: {},
            searchShipment: true
        }
        this.onSearch = this.onSearch.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    async componentDidMount() {
        const { userId, clientId } = this.state
        this.setState({ isLoading: true })

        if (userId) {
            await this.getClientUserData(clientId, userId)
            await this.getClientUserReportsData(clientId, userId)
            await this.getClientUserDashboardsData(clientId, userId)
        } else {
            await this.GetUserEmailSubscriptionList()
            await this.GetClientUserPermissionsList()
            await this.getClientUserReportsData(clientId, 'new')
            await this.getClientUserDashboardsData(clientId, 'new')
        }

        getAppInfo(clientId, data => {
            this.setState({ appInfo: data })
            if (!userId) {
                const copy = JSON.parse(JSON.stringify(data))
                this.setState({
                    user: {
                        modulePermissionArray: copy.clientModulePermissionArray
                    }
                })
            }
        })

        this.setState({ isLoading: false })
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
            this._asyncRequest.cancel()
        }
    }

    updateUserField = (value, key) => {
        this.setState(prevState => {
            let user = Object.assign({}, prevState.user)
            user[key] = value
            return { user }
        })
    }

    onSearch(e) {
        this.handleChange(e, 'searchShipment', false)
    }

    handleChange(event, field, isRequired) {
        this.setState({
            showFailAlert: false,
            showSuccessAlert: false
        })

        let value = event.target.value
        let isValidField = true
        let isValidForm = true
        let msgError = ''

        if (value.length === 0 && isRequired) {
            isValidField = false
            isValidForm = false
            msgError = 'cannot be empty.'
        }

        switch (field) {
            case 'user-first-name':
                this.setState({
                    errorTextUserFirstName: isValidField
                        ? ''
                        : 'First Name ' + msgError,
                    isValidUserFirstName: isValidField
                })
                this.updateUserField(value, 'firstName')
                break

            case 'user-last-name':
                this.setState({
                    errorTextUserLastName: isValidField
                        ? ''
                        : 'Last Name ' + msgError,
                    isValidUserLastName: isValidField
                })
                this.updateUserField(value, 'lastName')
                break

            case 'user-email':
                if (isValidField) {
                    var emailValid = emailValidation(value)

                    if (!emailValid) {
                        isValidField = false
                        msgError = 'Email address is not valid.'
                        isValidForm = false
                    }
                } else {
                    var _msgErrorEmail = 'Email'.concat(' ', msgError)
                    msgError = _msgErrorEmail
                }

                this.setState({
                    errorTextUserEmail: msgError,
                    isValidUserEmail: isValidField
                })

                this.updateUserField(value, 'email')
                break

            case 'searchShipment':
                const searchShipment = this.state.searchShipment
                this.setState({ searchShipment: !searchShipment })
                break

            default:
                break
        }

        this.setState({ isValidForm: isValidForm })
    }

    handleSubmit = async event => {
        const { userId, user, clientId, isValidForm, emailSubscriptionList, clientUserPermissionsList } = this.state
        event.preventDefault()

        if (isValidForm) {
            let data = null
            let isNew = false

            data = {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                clientId: clientId,
                modulePermissionArray: user.modulePermissionArray,
                emailSubscriptionsArray: emailSubscriptionList,
                clientUserPermissionArray: clientUserPermissionsList
            }

            if (userId && user.id) {
                data['id'] = user.id
            } else {
                isNew = true
                data['clientId'] = clientId
            }
            this.saveClientUserDetails(data, isNew)
        }
    }

    handleCancel(event) {
        window.location.href = usersPath.pathname + '/' + this.state.clientId
    }

    handleCloseAlert() {
        this.setState(prevState => {
            let alert = Object.assign({}, prevState.alert)
            alert.open = false
            alert.success = false
            alert.message = ''
            return { alert }
        })
    }

    renderAlert = () => {
        const alert = this.state.alert
        return alert.open ? (
            <div className="w-100">
                <Alert
                    variant="filled"
                    severity={alert.success ? 'success' : 'error'}
                    onClose={() => {
                        this.handleCloseAlert()
                    }}>
                    {alert.message}
                </Alert>
            </div>
        ) : null
    }

    renderInput = (
        id,
        label,
        placeholder,
        orgValue,
        required,
        adornment,
        valid = null,
        errorText = null
    ) => {
        const { user } = this.state
        const { classes } = this.props
        var params = {}
        if (valid !== null && errorText !== null) {
            params['helperText'] = !this.state[valid]
                ? this.state[errorText]
                : ''
            params['error'] = !this.state[valid]
        }

        return (
            <Grid item xs={12}>
                <FormControl>
                    <TextField
                        className={classes.textFieldForm}
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        required={required}
                        value={user[orgValue] || ''}
                        onChange={e => this.handleChange(e, id, required)}
                        {...params}
                        InputProps={
                            adornment
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <RecentActorsIcon />
                                          </InputAdornment>
                                      )
                                  }
                                : {}
                        }
                    />
                </FormControl>
            </Grid>
        )
    }

    handleModuleChange = (key, value) => {
        const { user } = this.state
        let permissions = null
        // check if key exists in array
        if (user.modulePermissionArray.some(e => e.propertyKey === key)) {
            permissions = user.modulePermissionArray.map(permission => {
                if (permission.propertyKey === key) {
                    permission['value'] = value
                }
                return permission
            })
        } else {
            permissions = [
                ...user.modulePermissionArray,
                {
                    propertyKey: key,
                    value: value
                }
            ]
        }

        this.setState({
            user: {
                ...user,
                modulePermissionArray: permissions
            }
        })
    }

    handleEmailSubscriptionChange = (key, value) => {
        const { emailSubscriptionList } = this.state
        const subscriptions = emailSubscriptionList.map(subscription => {
            if (subscription.subscriptionId === key) {
                subscription['subscribed'] = value
            }
            return subscription
        })

        this.setState({
            emailSubscriptionList: subscriptions,
            isValidForm: true
        })
    }

    handleClientUserPermissionsChange = (key, value) => {
        const { clientUserPermissionsList } = this.state
        const permissions = clientUserPermissionsList.map(permission => {
            if (permission.permissionId === key) {
                permission['enabled'] = value
            }
            return permission
        })

        this.setState({
            clientUserPermissionsList: permissions,
            isValidForm: true
        })
    }


    handleReportChange = (key, value) => {
        const { reportAccessList } = this.state
        const reports = reportAccessList.map(report => {
            if (report.reportId === key) {
                report['isChecked'] = value
            }
            return report
        })

        this.setState({
            reportAccessList: reports
        })
    }
    handleDashboardChange = (key, value) => {
        const { dashboardAccessList } = this.state
        const dashboards = dashboardAccessList.map(dashboard => {
            if (dashboard.dashboardId === key) {
                dashboard['isChecked'] = value
            }
            return dashboard
        })

        this.setState({
            dashboardAccessList: dashboards
        })
    }

    render() {
        const currentState = this.state
        const { appInfo } = this.state
        const {
            reportAccessList,
            dashboardAccessList,
            emailSubscriptionList,
            clientUserPermissionsList,
            displayNoDashboards,
            displayNoReports
        } = this.state
        const { classes } = this.props
        const canEditSearchShipment =
            extractSearchShipment(appInfo.modulePermissionArray) &&
            extractSearchShipment(appInfo.clientModulePermissionArray)
        const canEditCreateShipment =
            extractCreateShipment(appInfo.modulePermissionArray) &&
            extractCreateShipment(appInfo.clientModulePermissionArray)
        const canEditSeeCustoms =
            extractSeeCustoms(appInfo.modulePermissionArray) &&
            extractSeeCustoms(appInfo.clientModulePermissionArray)
        const canEditSeeAccounting =
            extractSeeAccounting(appInfo.modulePermissionArray) &&
            extractSeeAccounting(appInfo.clientModulePermissionArray)
        const canEditSeeBookings =
            extractSeeBooking(appInfo.modulePermissionArray) &&
            extractSeeBooking(appInfo.clientModulePermissionArray)
        const canEditSeeOrders =
            extractSeeOrder(appInfo.modulePermissionArray) &&
            extractSeeOrder(appInfo.clientModulePermissionArray)
        const canEditCreateOrders =
            extractCreateOrder(appInfo.modulePermissionArray) &&
            extractCreateOrder(appInfo.clientModulePermissionArray)
        const canEditSeeContainers =
            extractSeeContainers(appInfo.modulePermissionArray) &&
            extractSeeContainers(appInfo.clientModulePermissionArray)
        return (
            <CardContent class="p-0 psa-shipment-paper-card">
                {this.renderAlert()}
                <Paper className={classes.paper} class="psashipmentpaper">
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={4}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item xs={12}>
                                    <CardTitle checkOn={false}>
                                        <form
                                            onSubmit={this.handleSubmit}
                                            noValidate
                                            autoComplete="off">
                                            <Grid
                                                container
                                                direction="column"
                                                spacing={4}>
                                                <Grid item xs>
                                                    {this.renderInput(
                                                        'user-first-name',
                                                        'First Name',
                                                        'First Name',
                                                        'firstName',
                                                        true,
                                                        true,
                                                        'isValidUserFirstName',
                                                        'errorTextUserFirstName'
                                                    )}
                                                </Grid>
                                                <Grid item xs>
                                                    {this.renderInput(
                                                        'user-last-name',
                                                        'Last Name',
                                                        'Last Name',
                                                        'lastName',
                                                        true,
                                                        false,
                                                        'isValidUserLastName',
                                                        'errorTextUserLastName'
                                                    )}
                                                </Grid>
                                                <Grid item xs>
                                                    {this.renderInput(
                                                        'user-email',
                                                        'Email',
                                                        'Email',
                                                        'email',
                                                        true,
                                                        false,
                                                        'isValidUserEmail',
                                                        'errorTextUserEmail'
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardTitle>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} className="d-flex">
                                    <CardTitle title="User Role" checkOn={false}>
                                        <Typography gutterBottom variant="h6">
                                            {usrRole}
                                        </Typography>
                                    </CardTitle>
                                </Grid>
                                <Grid item xs={8} className="d-flex">
                                    <CardTitle title="Module Access" checkOn={false}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={event => {
                                                        this.handleModuleChange(
                                                            'ShipmentSearch',
                                                            event.target.checked
                                                        )
                                                    }}
                                                    color="default"
                                                    name="search"
                                                    disabled={!canEditSearchShipment}
                                                    checked={
                                                        !canEditSearchShipment
                                                            ? false
                                                            : extractSearchShipment(
                                                                this.state.user
                                                                    .modulePermissionArray
                                                            )
                                                    }
                                                />
                                            }
                                            label={'Search Shipment'}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={event => {
                                                        this.handleModuleChange(
                                                            'ShipmentCreate',
                                                            event.target.checked
                                                        )
                                                    }}
                                                    color="default"
                                                    name="search"
                                                    disabled={!canEditCreateShipment}
                                                    checked={
                                                        !canEditCreateShipment
                                                            ? false
                                                            : extractCreateShipment(
                                                                this.state.user
                                                                    .modulePermissionArray
                                                            )
                                                    }
                                                />
                                            }
                                            label={'Create Shipment'}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={event => {
                                                        this.handleModuleChange(
                                                            'Customs',
                                                            event.target.checked
                                                        )
                                                    }}
                                                    color="default"
                                                    name="search"
                                                    disabled={!canEditSeeCustoms}
                                                    checked={
                                                        !canEditSeeCustoms
                                                            ? false
                                                            : extractSeeCustoms(
                                                                this.state.user
                                                                    .modulePermissionArray
                                                            )
                                                    }
                                                />
                                            }
                                            label={'Customs'}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={event => {
                                                        this.handleModuleChange(
                                                            'Accounting',
                                                            event.target.checked
                                                        )
                                                    }}
                                                    color="default"
                                                    name="search"
                                                    disabled={!canEditSeeAccounting}
                                                    checked={
                                                        !canEditSeeAccounting
                                                            ? false
                                                            : extractSeeAccounting(
                                                                this.state.user
                                                                    .modulePermissionArray
                                                            )
                                                    }
                                                />
                                            }
                                            label={'Accounting'}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={event => {
                                                        this.handleModuleChange(
                                                            'Booking',
                                                            event.target.checked
                                                        )
                                                    }}
                                                    color="default"
                                                    name="search"
                                                    disabled={!canEditSeeBookings}
                                                    checked={
                                                        !canEditSeeBookings
                                                            ? false
                                                            : extractSeeBooking(
                                                                this.state.user
                                                                    .modulePermissionArray
                                                            )
                                                    }
                                                />
                                            }
                                            label={'Bookings'}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={event => {
                                                        this.handleModuleChange(
                                                            'Order',
                                                            event.target.checked
                                                        )
                                                    }}
                                                    color="default"
                                                    name="search"
                                                    disabled={!canEditSeeOrders}
                                                    checked={
                                                        !canEditSeeOrders
                                                            ? false
                                                            : extractSeeOrder(
                                                                this.state.user
                                                                    .modulePermissionArray
                                                            )
                                                    }
                                                />
                                            }
                                            label={'Orders'}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={event => {
                                                        this.handleModuleChange(
                                                            'CreateOrder',
                                                            event.target.checked
                                                        )
                                                    }}
                                                    color="default"
                                                    name="search"
                                                    disabled={!canEditCreateOrders}
                                                    checked={
                                                        !canEditCreateOrders
                                                            ? false
                                                            : extractCreateOrder(
                                                                this.state.user
                                                                    .modulePermissionArray
                                                            )
                                                    }
                                                />
                                            }
                                            label={'Create Order'}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={event => {
                                                        this.handleModuleChange(
                                                            'Container',
                                                            event.target.checked
                                                        )
                                                    }}
                                                    color="default"
                                                    name="search"
                                                    disabled={!canEditSeeContainers}
                                                    checked={
                                                        !canEditSeeContainers
                                                            ? false
                                                            : extractSeeContainers(
                                                                this.state.user
                                                                    .modulePermissionArray
                                                            )
                                                    }
                                                />
                                            }
                                            label={'Containers'}
                                        />
                                    </CardTitle>
                                </Grid>
                                <Grid item xs={4}>
                                    <CardTitle title="Report Access" checkOn={false}>
                                        {displayNoReports && (
                                            <div>No matching data found</div>
                                        )}
                                        {reportAccessList.map(report => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={event => {
                                                            this.handleReportChange(
                                                                report.reportId,
                                                                event.target.checked
                                                            )
                                                        }}
                                                        color="default"
                                                        name=""
                                                        checked={report.isChecked}
                                                    />
                                                }
                                                label={report.reportName}
                                            />
                                        ))}
                                    </CardTitle>
                                </Grid>
                                <Grid item xs={4}>
                                    <CardTitle title="Dashboard Access" checkOn={false}>
                                        {displayNoDashboards && (
                                            <div>No matching data found</div>
                                        )}
                                        {dashboardAccessList.map(dashboard => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={event => {
                                                            this.handleDashboardChange(
                                                                dashboard.dashboardId,
                                                                event.target.checked
                                                            )
                                                        }}
                                                        color="default"
                                                        name=""
                                                        checked={dashboard.isChecked}
                                                    />
                                                }
                                                label={dashboard.dashboardName}
                                            />
                                        ))}
                                    </CardTitle>
                                </Grid>
                                <Grid item xs={4}>
                                    <CardTitle title="Email Subscriptions" checkOn={false}>
                                        {emailSubscriptionList.map(
                                            subscription => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={event => {
                                                                this.handleEmailSubscriptionChange(
                                                                    subscription.subscriptionId,
                                                                    event
                                                                        .target
                                                                        .checked
                                                                )
                                                            }}
                                                            color="default"
                                                            name=""
                                                            checked={
                                                                subscription.subscribed
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        subscription.subscriptionName
                                                    }
                                                />
                                            )
                                        )}
                                    </CardTitle>
                                </Grid>
                                <Grid item xs={4}>
                                    <CardTitle title="Client User Permissions" checkOn={false}>
                                        {clientUserPermissionsList.map(
                                            permission => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={event => {
                                                                this.handleClientUserPermissionsChange(
                                                                    permission.permissionId,
                                                                    event
                                                                        .target
                                                                        .checked
                                                                )
                                                            }}
                                                            color="default"
                                                            name=""
                                                            checked={
                                                                permission.enabled
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        permission.permissionName
                                                    }
                                                />
                                            )
                                        )}
                                    </CardTitle>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        onClick={e => this.handleSubmit(e)}
                                        disabled={
                                            !this.state.isValidForm ||
                                            this.state.isPosting ||
                                            this.state.isLoading
                                        }>
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        size="medium"
                                        color="secondary"
                                        variant="contained"
                                        onClick={e => this.handleCancel()}
                                        disabled={currentState.isPosting}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </CardContent>
        )
    }

    async getClientUserData(clientId, userId) {
        const token = await authService.getAccessToken()
        const response = await fetch('api/users/' + userId + '?clientId=' + clientId, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const data = await response.json()
            this.setState({ 
                user: data,
                emailSubscriptionList: data.emailSubscriptionsArray ? data.emailSubscriptionsArray : [],
                clientUserPermissionsList: data.clientUserPermissionArray ? data.clientUserPermissionArray : []
            })
        }
    }

    //to save the client->user->reports
    async getClientUserReportsData(clientid, userid) {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/users/GetClientUserBIReports/' + clientid + '/' + userid,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
            .then(response => response.json())
            .then(data => {
                this.setState({
                    reportAccessList: data
                })
                if (data.length == 0) {
                    this.setState({
                        displayNoReports: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    displayNoReports: true,
                    isPosting: false
                })
            })
    }

    //to get the client->user->dashboards
    async getClientUserDashboardsData(clientid, userid) {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/users/GetClientUserBIDashboards/' + clientid + '/' + userid,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dashboardAccessList: data
                })
                if (data.length == 0) {
                    this.setState({
                        displayNoDashboards: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    displayNoDashboards: true,
                    isPosting: false
                })
            })
    }

    //to save the client->user->reports
    async saveClientUserReportsData() {
        const token = await authService.getAccessToken()
        const { reportAccessList } = this.state
        const { clientId, userId } = this.state
        let list = []
        reportAccessList.map(x => {
            if (x.isChecked) {
                list.push({
                    UserId: userId,
                    ClientId: clientId,
                    PSAOrganizationId: null,
                    ReportID: x.reportId,
                    ReportName: x.reportName
                })
            }
        })
        let data = {
            UserId: userId,
            ClientId: clientId,
            Reports: list
        }
        let errText = ''
        await fetch('api/users/SaveUserBIReports', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    // Okay
                } else {
                    errText = 'Code[' + response.status + ']'

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                }
            })

            .then(d => {
                this.setState({
                    isPosting: false
                })
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                this.setState({
                    isPosting: false
                })
            })
    }

    //to save the client->user->dashboards
    async saveClientUserDashboardsData(isNew) {
        const token = await authService.getAccessToken()
        const { dashboardAccessList } = this.state
        const { clientId, userId } = this.state
        let list = []
        dashboardAccessList.map(x => {
            if (x.isChecked) {
                list.push({
                    UserId: userId,
                    ClientId: clientId,
                    PSAOrganizationId: null,
                    DashboardID: x.dashboardId,
                    DashboardName: x.dashboardName
                })
            }
        })

        let data = {
            UserId: userId,
            ClientId: clientId,
            Dashboards: list
        }
        let errText = ''
        await fetch('api/users/SaveUserBIDashboards', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    // okay
                } else {
                    errText = 'Code[' + response.status + ']'

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                }
            })

            .then(d => {
                this.setState({
                    isPosting: false
                })
                if (isNew) {
                    //Go bak to the List
                    var path = usersPath.pathname + '/' + this.state.clientId
                    window.location.href = path
                }
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                this.setState({
                    isPosting: false
                })
            })
    }
    //User -> reports & dashboards (save, get)

    async GetUserEmailSubscriptionList() {
        const token = await authService.getAccessToken()
        const response = await fetch('api/users/GetUserEmailSubscriptionList', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const data = await response.json()
            this.setState({ emailSubscriptionList: data })
        }
    }

    async GetClientUserPermissionsList() {
        const token = await authService.getAccessToken()
        const response = await fetch('api/users/GetClientUserPermissionsList', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const data = await response.json()
            this.setState({ clientUserPermissionsList: data })
        }
    }

    async saveClientUserDetails(data, isNew) {
        this.setState({ isPosting: true })
        let errText = ''
        const token = await authService.getAccessToken()
        let url = isNew
            ? 'api/users/CreateUser'
            : 'api/users/AdminPutUser/' + data.id
        let _method = isNew ? 'POST' : 'PUT'

        fetch(url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, *',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = true
                        alert.message = 'User information is saved.'
                        return { alert }
                    })
                    return response.json()
                } else {
                    errText = 'Code [' + response.status + '] '
                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                    return response.text()
                }
            })
            .then(d => {
                if (d.id) {
                    this.setState({ user: d, userId: d.id })
                    this.saveClientUserReportsData()
                    this.saveClientUserDashboardsData(isNew)
                    // if (isNew) {
                    //     //Go bak to the List
                    //     var path =
                    //         usersPath.pathname + '/' + this.state.clientId
                    //     window.location.href = path
                    // }
                } else {
                    this.setState({ user: data })
                }
                this.setState({
                    isPosting: false,
                    isValidForm: true
                })
            })
            .catch(error => {
                this.setState({
                    isPosting: false,
                    isValidForm: false
                })
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                console.log(error)
            })
    }
}
export default withStyles(styles)(ClientUserDetails)
