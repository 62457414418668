import authService from '../api-authorization/AuthorizeService'

export async function getContainerExtraData(shipmentData, containerNumber, clientId) {

    const queryString = require('query-string')
    const qs = {
        ClientId: clientId,
        ShipmentSearchNumber: shipmentData.shipmentNumber
    }

    const token = await authService.getAccessToken()
    const shipmentExtraResponse = await fetch(
        `api/shipments/getcontainerextra?` + queryString.stringify(qs), 
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

    if (shipmentExtraResponse.ok) {
        const shipmentExtra = await shipmentExtraResponse.json()

        if (!shipmentExtra) {
            return null
        }

        var container = shipmentExtra.containers.find(
            c => c.containerNumber === containerNumber
        )

        if (!container) {
            return null
        }

        // need to add routing info to container
        container.routingInfo = shipmentExtra.routingInfo

        // need to add geolocation info to container
        container.geoLocationOrigin = shipmentData.geoLocationOrigin
        container.geoLocationDestination = shipmentData.geoLocationDestination
        container.geoLocationPortOfLoading = shipmentData.geoLocationPortOfLoading
        container.geoLocationPortOfDischarge = shipmentData.geoLocationPortOfDischarge
        container.geoLocationCurrentLocation = shipmentExtra.geoLocationCurrentLocation
        container.origin = shipmentData.origin
        container.destination = shipmentData.destination

        return container

    }
    else {
        return null
    }
}