import React, { PureComponent } from 'react'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { CardContent } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import {
    Button,
    TextField,
    IconButton,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import { Input, InputLabel, InputAdornment } from '@material-ui/core'
import { FormControl, Grid } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'
import { phoneNumberValidation } from '../components/shared/Helpers'
import { emailValidation } from '../components/shared/Helpers'
import CardTitle from './shared/CardTitle'
import { withStyles } from '@material-ui/core/styles'
import {
    extractCreateShipment,
    extractSearchShipment,
    extractSeeCustoms,
    extractSeeAccounting,
    extractSeeBooking,
    extractSeeOrder,
    extractSeeContainers,
    extractCreateOrder
} from '../utils/permissions'
import { getAppInfo, getClientData } from '../api/api'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { MenuItem, Select, Typography } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { NavLink } from 'react-router-dom'
import { getClientViewsList } from './shared/Views'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    textFieldFormFullWidth: {
        width: '100%'
    }
})

const clientsPath = { pathname: `${ApplicationPaths.Clients}` }
const usersPath = { pathname: `${ApplicationPaths.Users}` }

class ClientDetails extends PureComponent {
    static displayName = ClientDetails.name

    constructor(props) {
        super(props)
        this.state = {
            clientId:
                props.match.params.id !== null &&
                props.match.params.id.toLowerCase() !== 'new'
                    ? props.match.params.id
                    : null,
            isLoading: props.match.params.id !== null,
            reportAccessList: [],
            dashboardAccessList: [],
            displayNoReports: true,
            displayNoDashboards: true,
            isPosting: false,
            client: {},
            requiredFields: [],
            organization: null,
            showPassword: false,
            integrationPassword: '',
            isValidForm: true,
            isValidCustomerId: true,
            errorTextCustomerId: ' ',
            isValidName: true,
            errorTextName: '',
            isValidContactFirstName: true,
            errorTextContactFirstName: '',
            isValidContactLastName: true,
            errorTextContactLastName: '',
            isValidContactEmail: true,
            errorTextContactEmail: '',
            isValidContactPhoneNumber: true,
            errorTextContactPhoneNumber: '',
            isValidContactCountryCode: true,
            errorTextContactCountryCode: '',
            isValidCW1CompanyCode: true,
            errorTextCW1CompanyCode: '',
            isValidIntegrationCompanyCode: true,
            errorTextIntegrationCompanyCode: '',
            isValidCW1BranchCode: true,
            errorTextCW1BranchCode: '',
            alert: {
                open: false,
                success: false,
                message: ''
            },
            appInfo: {},
            searchShipment: true,
            expanded: 'panel1',
            crawlerRunning: false,
            clientViews: []
        }
        this.onSearch = this.onSearch.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleACCChange = this.handleACCChange.bind(this)
    }

    componentDidMount() {
        const { clientId } = this.state
        this.populateConfig()
        this.fetchClientViews()
        if (clientId) {
            this.fetchClientData(clientId)
            this.getClientReportsData(clientId)
            this.getClientDashboardsData(clientId)
        } else {
            this.getClientReportsData('new')
            this.getClientDashboardsData('new')
        }
        getAppInfo(clientId, data => {
            this.setState({ appInfo: data })
            if (!clientId) {
                const copy = JSON.parse(JSON.stringify(data))
                this.setState({
                    client: {
                        modulePermissionArray: copy.modulePermissionArray
                    }
                })
            }
        })
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
            this._asyncRequest.cancel()
        }
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    handleMouseDown = event => {
        event.preventDefault()
    }

    handleCancel(event) {
        window.location.href = clientsPath.pathname
    }

    handleRunCrawler(event) {
        this.setState({ crawlerRunning: true })
        this.runCrawler()
        this.setState({ crawlerRunning: false })
    }

    updateClientField = (value, key) => {
        this.setState(prevState => {
            let client = Object.assign({}, prevState.client)
            client[key] = value
            return { client }
        })
    }

    onSearch(e) {
        this.handleChange(e, 'searchShipment', false)
    }

    handleCheckboxChange = (event, field) => {
        const checked = event.target.checked
        
        switch (field) {
            case 'usesOpenTrack':
                this.updateClientField(checked, 'isOpenTrack')
                break
            case 'usesOpenTrackTracking':
                this.updateClientField(checked, 'isOpenTrackTracking')
                break
            default:
                break
        }

    }

    handleBookingStepChange = (step, enabled) => {
        const { client } = this.state
        const bookingSteps = client?.bookingSteps ? JSON.parse(client.bookingSteps) : []
        const updatedSteps = bookingSteps.map(s => {
            if (s.index === step) {
                s.enabled = enabled
            }
            return s
        })

        this.updateClientField(JSON.stringify(updatedSteps), 'bookingSteps')
    }
    
    handleChange(event, field, isRequired) {
        this.setState({
            showFailAlert: false,
            showSuccessAlert: false
        })

        let value = event.target.value
        let isValidField = true
        let isValidForm = true
        let msgError = ''

        if (value.length === 0 && isRequired) {
            isValidField = false
            isValidForm = false
            msgError = 'cannot be empty.'
        }

        switch (field) {
            case 'customer-id':
                this.setState({
                    errorTextCustomerId: 'Customer ID ' + msgError,
                    isValidCustomerId: isValidField,
                    isUpdatedClient: true
                })
                this.updateClientField(value, 'customerId')

                break

            case 'name':
                this.setState({
                    errorTextName: 'Customer Name ' + msgError,
                    isValidName: isValidField,
                    isUpdatedClient: true
                })
                this.updateClientField(value, 'name')

                break

            case 'contact-first-name':
                this.setState({
                    errorTextContactFirstName: 'First Name ' + msgError,
                    isValidContactFirstName: isValidField,
                    isUpdatedClient: true
                })
                this.updateClientField(value, 'contactFirstName')
                break

            case 'contact-last-name':
                this.setState({
                    errorTextContactLastName: 'Last Name ' + msgError,
                    isValidContactLastName: isValidField,
                    isUpdatedClient: true
                })
                this.updateClientField(value, 'contactLastName')
                break

            case 'contact-email':
                if (isValidField) {
                    var emailValid = emailValidation(value)

                    if (!emailValid) {
                        isValidField = false
                        msgError = 'Email address is not valid.'
                        isValidForm = false
                    }
                } else {
                    var _msgErrorEmail = 'Email'.concat(' ', msgError)
                    msgError = _msgErrorEmail
                }

                this.setState({
                    errorTextContactEmail: msgError,
                    isValidContactEmail: isValidField,
                    isUpdatedClient: true
                })

                this.updateClientField(value, 'contactEmail')
                break

            case 'contact-phone-number':
                if (isValidField) {
                    var phoneValid = phoneNumberValidation(value)

                    if (!phoneValid && value) {
                        isValidField = false
                        msgError = 'Phone number is not valid.'
                        isValidForm = false
                    }
                } else {
                    var _msgErrorPhone = 'Phone'.concat(' ', msgError)
                    msgError = _msgErrorPhone
                }

                this.setState({
                    errorTextContactPhoneNumber: msgError,
                    isValidContactPhoneNumber: isValidField,
                    isUpdatedClient: true
                })

                this.updateClientField(value, 'contactPhoneNumber')
                this.setState({ isUpdatedClient: true })
                break

            case 'contact-address-line1':
                this.updateClientField(value, 'contactAddressLine1')
                this.setState({ isUpdatedClient: true })
                break

            case 'contact-address-country':
                this.updateClientField(value, 'contactAddressCountry')
                this.setState({ isUpdatedClient: true })
                break

            case 'contact-address-country-code':

                if (value && value.length > 2 || value && value.length < 2) {
                    isValidField = false
                    msgError = 'Country code must be 2 characters.'
                    isValidForm = false
                }

                this.setState({
                    errorTextContactCountryCode: msgError,
                    isValidContactCountryCode: isValidField,
                    isUpdatedClient: true
                })

                this.updateClientField(value, 'contactAddressCountryCode')
                this.setState({ isUpdatedClient: true })
                break

            case 'contact-address-region':
                this.updateClientField(value, 'contactAddressRegion')
                this.setState({ isUpdatedClient: true })
                break

            case 'contact-address-city':
                this.updateClientField(value, 'contactAddressCity')
                this.setState({ isUpdatedClient: true })
                break

            case 'contact-address-zip-code':
                this.updateClientField(value, 'contactAddressZipCode')
                this.setState({ isUpdatedClient: true })
                break

            case 'integration-company-code':
                this.updateClientField(value, 'integrationCompanyCode')
                this.setState({ isUpdatedClient: true })
                break

            case 'integration-user-name':
                this.updateClientField(value, 'integrationUserName')
                this.setState({ isUpdatedClient: true })
                break

            case 'integration-password':
                this.setState({
                    integrationPassword: value
                })
                this.updateClientField(value, 'integrationPassword')
                this.setState({ isUpdatedClient: true })
                break

            case 'cw1-company-code':
                if (value && value.length > 3 || value && value.length < 3) {
                    isValidField = false
                    msgError = 'CW1 Company Code must be 3 characters.'
                    isValidForm = false
                }

                this.setState({
                    errorTextCW1CompanyCode: msgError,
                    isValidCW1CompanyCode: isValidField,
                    isUpdatedClient: true
                })

                this.updateClientField(value.toUpperCase(), 'cW1CompanyCode')
                this.setState({ isUpdatedClient: true })
                break

            case 'cw1-branch-code':
                if (value && value.length > 3 || value && value.length < 3) {
                    isValidField = false
                    msgError = 'CW1 Branch Code must be 3 characters.'
                    isValidForm = false
                }

                this.setState({
                    errorTextCW1BranchCode: msgError,
                    isValidCW1BranchCode: isValidField,
                    isUpdatedClient: true
                })

                this.updateClientField(value.toUpperCase(), 'cW1BranchCode')
                this.setState({ isUpdatedClient: true })
                break

            case 'searchShipment':
                const searchShipment = this.state.searchShipment
                this.setState({ searchShipment: !searchShipment })
                break

            case 'client-view':
                this.updateClientField(value, 'clientViewId')
                this.setState({ isUpdatedClient: true })
                break

            case 'usesOpenTrack':
                this.updateClientField(value, 'isOpenTrack')
                this.setState({ isUpdatedClient: true })
                break

            default:
                break
        }

        this.setState({ isValidForm: isValidForm })
    }

    handleValidation() {
        var currentState = this.state
        let { requiredFields, client } = currentState
        let isValidForm = true

        requiredFields.map(function(item, i) {
            if (!client[item.orgValue]) {
                currentState[item.valid] = false
                currentState[item.errorText] = item.label + ' cannot be empty.'
                isValidForm = false
            }

            return isValidForm
        })

        this.setState(currentState)
        this.setState({ isValidForm: isValidForm })
        return isValidForm
    }

    handleModuleChange = (key, value) => {
        const { client } = this.state
        let permissions = null
        // check if key exists in array
        if (client.modulePermissionArray.some(e => e.propertyKey === key)) {
            permissions = client.modulePermissionArray?.map(permission => {
                if (permission.propertyKey === key) {
                    permission['value'] = value
                }
                return permission
            })
        } else {
            permissions = [
                ...client.modulePermissionArray,
                {
                    propertyKey: key,
                    value: value
                }
            ]
        }

        this.setState({
            client: {
                ...client,
                modulePermissionArray: permissions
            }
        })
    }
    handleReportChange = (key, value) => {
        const { reportAccessList } = this.state
        const reports = reportAccessList?.map(report => {
            if (report.reportId === key) {
                report['isChecked'] = value
            }
            return report
        })

        this.setState({
            reportAccessList: reports
        })
    }
    handleDashboardChange = (key, value) => {
        const { dashboardAccessList } = this.state
        const dashboards = dashboardAccessList?.map(dashboard => {
            if (dashboard.dashboardId === key) {
                dashboard['isChecked'] = value
            }
            return dashboard
        })
        this.setState({
            dashboardAccessList: dashboards
        })
    }

    handleSubmit = async event => {
        const { clientId, client, organization } = this.state
        event.preventDefault()

        const isValid = this.handleValidation()

        if (isValid) {
            let isNew = true

            if (clientId && client.id) {
                isNew = false
            }
            this.saveClientData(client, isNew)
        }
    }

    handleCloseAlert() {
        this.setState(prevState => {
            let alert = Object.assign({}, prevState.alert)
            alert.open = false
            alert.success = false
            alert.message = ''
            return { alert }
        })
    }

    renderSetUsersModuleAccessButton = () => {
        return (
            <Button
                style={{ marginBottom: '10px' }}
                variant="contained"
                color="primary"
                onClick={this.handleSetUserModuleAccess}>
                Set Client User Module Access
            </Button>
        )
    }

    handleSetUserModuleAccess = async () => {
        const { client } = this.state

        if (!window.confirm('Set all client user permissions for this client?')) {
            return
        }

        const response = await fetch(
            'api/clients/UpdateClientUsersModuleAccess',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await authService.getAccessToken()}`
                },
                body: JSON.stringify(client)
            }
        )
        if (!response.ok) {
            this.setState({
                alert: {
                    open: true,
                    success: false,
                    message: 'Error updating client user module access'
                }
            })
        } else {
            this.setState({
                alert: {
                    open: true,
                    success: true,
                    message: 'Client user module access updated successfully'
                }
            })
        }
    }

    async populateConfig() {
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({ organization: data })
    }

    async fetchClientViews() {
        const views = await getClientViewsList()
        this.setState({ clientViews: views })
    }

    renderInput = (
        id,
        label,
        placeholder,
        orgValue,
        required,
        disabled,
        valid = null,
        errorText = null
    ) => {
        const { client } = this.state
        const { classes } = this.props
        var params = {}
        if (valid !== null && errorText !== null) {
            params['helperText'] = !this.state[valid]
                ? this.state[errorText]
                : ''
            params['error'] = !this.state[valid]
        }

        if (required)
            this.state.requiredFields.push({
                orgValue,
                label,
                valid,
                errorText
            })

        return (
            <Grid item xs={12}>
                <FormControl>
                    <TextField
                        className={classes.textFieldForm}
                        id={id}
                        disabled={!client.id ? false : disabled}
                        required={required}
                        label={label}
                        placeholder={placeholder}
                        value={client[orgValue] || ''}
                        onChange={e => this.handleChange(e, id, required)}
                        {...params}
                    />
                </FormControl>
            </Grid>
        )
    }

    renderAlert = () => {
        const alert = this.state.alert
        return alert.open ? (
            <div className="w-100">
                <Alert
                    variant="filled"
                    severity={alert.success ? 'success' : 'error'}
                    onClose={() => {
                        this.handleCloseAlert()
                    }}>
                    {alert.message}
                </Alert>
            </div>
        ) : null
    }

    render() {
        const { backgroundColor, textColor, history } = this.props

        const { classes } = this.props
        const {
            organization,
            showPassword,
            client,
            clientId,
            appInfo,
            expanded,
            clientViews
        } = this.state
        const { integrationPassword } = client
        const { modulePermissionArray } = appInfo
        const bookingSteps = client?.bookingSteps ? JSON.parse(client.bookingSteps) : []

        const canEditCreateShipment = extractCreateShipment(
            modulePermissionArray
        )
        const canEditSearchShipment = extractSearchShipment(
            modulePermissionArray
        )
        const canEditSeeCustoms = extractSeeCustoms(modulePermissionArray)
        const canEditSeeAccounting = extractSeeAccounting(modulePermissionArray)
        const canEditSeeBookings = extractSeeBooking(modulePermissionArray)
        const canEditSeeOrders = extractSeeOrder(modulePermissionArray)
        const canEditSeeContainers = extractSeeContainers(modulePermissionArray)
        const canEditCreateOrder = extractCreateOrder(modulePermissionArray)
        const {
            reportAccessList,
            dashboardAccessList,
            displayNoDashboards,
            displayNoReports
        } = this.state
        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'
        const secodarycolor =
            organization !== null &&
            organization.colorThemeSecondaryRGB !== null
                ? `${organization.colorThemeSecondaryRGB}`
                : '#000000'
        const teritorycolor =
            organization !== null && organization.colorThemeTertiaryRGB !== null
                ? `${organization.colorThemeTertiaryRGB}`
                : '#ffffff'
        const themeRGB =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#000000'
        const themeTextColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'
        const lastCrawlResultParsed =
            client['lastCrawlerResult'] !== null
                ? new Date(
                      String(client['lastCrawlerResult']).substring(0, 24)
                  ).toLocaleString() +
                  String(client['lastCrawlerResult']).substring(24)
                : '-'

        return (
            <div>
                <CardContent class="p-0 psa-shipment-paper-card">
                    {this.renderAlert()}
                    <Paper className={classes.paper} class="psashipmentpaper">
                        <form
                            onSubmit={this.handleSubmit}
                            noValidate
                            autoComplete="off">
                            <div class="accordian-body-padding">
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        xs={12}
                                        className="mb-0"
                                        spacing={0}
                                        class="mb-4 w-100">
                                        <Grid
                                            item
                                            xs={12}
                                            direction="column"
                                            spacing={4}>
                                            <div className="no-p-0 custom-psa-accordian">
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel1'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel1'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                className="font-12">
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>
                                                                Customer
                                                                Information
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'customer-id',
                                                                        'Customer ID',
                                                                        'Customer ID',
                                                                        'customerId',
                                                                        true,
                                                                        false,
                                                                        'isValidCustomerId',
                                                                        'errorTextCustomerId'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'name',
                                                                        'Customer Name',
                                                                        'Customer Name',
                                                                        'name',
                                                                        true,
                                                                        false,
                                                                        'isValidName',
                                                                        'errorTextName'
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel2'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel2'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Company Address
                                                                Details
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-address-line1',
                                                                        'Street',
                                                                        'Enter Street Name ',
                                                                        'contactAddressLine1',
                                                                        false,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-address-city',
                                                                        'Town/City',
                                                                        'Enter Town/City Name',
                                                                        'contactAddressCity',
                                                                        false,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-address-region',
                                                                        'Region/State',
                                                                        'Enter Region/State',
                                                                        'contactAddressRegion',
                                                                        false,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-address-zip-code',
                                                                        'Zip Code',
                                                                        'Enter ZipCode',
                                                                        'contactAddressZipCode',
                                                                        false,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-address-country',
                                                                        'Country Name',
                                                                        'Enter Country',
                                                                        'contactAddressCountry',
                                                                        false,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-address-country-code',
                                                                        'Country Code (2 Characters)',
                                                                        'Enter Country Code',
                                                                        'contactAddressCountryCode',
                                                                        false,
                                                                        false,
                                                                        'isValidContactCountryCode',
                                                                        'errorTextContactCountryCode'
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel3'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel3'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>
                                                                Admin Contact
                                                                Details
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-first-name',
                                                                        'First Name',
                                                                        'First Name',
                                                                        'contactFirstName',
                                                                        true,
                                                                        false,
                                                                        'isValidContactFirstName',
                                                                        'errorTextContactFirstName'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-last-name',
                                                                        'Last Name',
                                                                        'Last Name',
                                                                        'contactLastName',
                                                                        true,
                                                                        false,
                                                                        'isValidContactLastName',
                                                                        'errorTextContactLastName'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-email',
                                                                        'Email',
                                                                        'xx@yyy.com',
                                                                        'contactEmail',
                                                                        true,
                                                                        false,
                                                                        'isValidContactEmail',
                                                                        'errorTextContactEmail'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'contact-phone-number',
                                                                        'Phone',
                                                                        '+1-541-754-3010',
                                                                        'contactPhoneNumber',
                                                                        false,
                                                                        false,
                                                                        'isValidContactPhoneNumber',
                                                                        'errorTextContactPhoneNumber'
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel12'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel12'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Booking 
                                                                Defaults
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                    <Grid
                                                                        item
                                                                        xl={4}
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        xs={12}
                                                                        className="xxl-2">
                                                                        {this.renderInput(
                                                                            'cw1-company-code',
                                                                            'CW1 Company Code',
                                                                            'Enter CW1 Company Code',
                                                                            'cW1CompanyCode',
                                                                            false,
                                                                            false,
                                                                            'isValidCW1CompanyCode',
                                                                            'errorTextCW1CompanyCode'
                                                                        )}
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xl={4}
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        xs={12}
                                                                        className="xxl-2">
                                                                        {this.renderInput(
                                                                            'cw1-branch-code',
                                                                            'CW1 Branch Code',
                                                                            'Enter CW1 Branch Code',
                                                                            'cW1BranchCode',
                                                                            false,
                                                                            false,
                                                                            'isValidCW1BranchCode',
                                                                            'errorTextCW1BranchCode'
                                                                        )}
                                                                    </Grid>
                                                                    {
                                                                        bookingSteps.map((step, index) => {
                                                                            if (step.index !== 0 && step.index !== 4) {
                                                                                return (
                                                                                    <Grid
                                                                                        item
                                                                                        xl={4}
                                                                                        lg={4}
                                                                                        md={4}
                                                                                        sm={6}
                                                                                        xs={12}
                                                                                        className="xxl-2">
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    onChange={event => {
                                                                                                        this.handleBookingStepChange(
                                                                                                            step.index,
                                                                                                            event
                                                                                                                .target
                                                                                                                .checked
                                                                                                        )
                                                                                                    }}
                                                                                                    color="default"
                                                                                                    checked={step.enabled}
                                                                                                />
                                                                                            }
                                                                                            label={step.name}
                                                                                        />
                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel5'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel5'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading w-100">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <h5
                                                                    checkOn={
                                                                        false
                                                                    }
                                                                    style={{
                                                                        color: primarycolor
                                                                    }}>
                                                                    <CardTitle
                                                                        title=""
                                                                        class="p-0"></CardTitle>
                                                                    WebServices
                                                                    Credentials
                                                                </h5>
                                                                <Button
                                                                    style={{
                                                                        background: primarycolor,
                                                                        color: textColor
                                                                    }}
                                                                    size="medium"
                                                                    color="primary"
                                                                    variant="contained"
                                                                    onClick={e =>
                                                                        this.handleRunCrawler(
                                                                            e
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this
                                                                            .state
                                                                            .crawlerRunning
                                                                    }>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .crawlerRunning
                                                                            ? 'Running...'
                                                                            : 'Run Crawler'
                                                                    }
                                                                </Button>
                                                            </div>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'integration-company-code',
                                                                        'Company Code',
                                                                        'Enter Company Code',
                                                                        'integrationCompanyCode',
                                                                        true,
                                                                        false,
                                                                        'isValidIntegrationCompanyCode',
                                                                        'errorTextIntegrationCompanyCode'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'integration-user-name',
                                                                        'User Name',
                                                                        'Enter Integration Username',
                                                                        'integrationUserName',
                                                                        false,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControl>
                                                                        <InputLabel>
                                                                            User
                                                                            Password
                                                                        </InputLabel>
                                                                        <Input
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }
                                                                            autoComplete="true"
                                                                            id="integration-password"
                                                                            type={
                                                                                showPassword
                                                                                    ? 'text'
                                                                                    : 'password'
                                                                            }
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    e,
                                                                                    'integration-password',
                                                                                    false,
                                                                                    false
                                                                                )
                                                                            }
                                                                            value={
                                                                                integrationPassword ||
                                                                                ''
                                                                            }
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label="toggle password visibility"
                                                                                        onClick={
                                                                                            this
                                                                                                .handleClickShowPassword
                                                                                        }
                                                                                        onMouseDown={
                                                                                            this
                                                                                                .handleMouseDown
                                                                                        }>
                                                                                        {showPassword ? (
                                                                                            <Visibility />
                                                                                        ) : (
                                                                                            <VisibilityOff />
                                                                                        )}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <TextField
                                                                        className={
                                                                            classes.textFieldFormFullWidth
                                                                        }
                                                                        id={
                                                                            'last-crawler-result'
                                                                        }
                                                                        disabled={
                                                                            true
                                                                        }
                                                                        multiline
                                                                        label={
                                                                            'Last Crawler Result'
                                                                        }
                                                                        value={
                                                                            lastCrawlResultParsed ||
                                                                            ''
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel4'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel4'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    style={{
                                                                        color: teritorycolor
                                                                    }}
                                                                    title=""
                                                                    class="font-12"></CardTitle>{' '}
                                                                Module Access
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={1}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'ShipmentSearch',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canEditSearchShipment
                                                                                }
                                                                                checked={
                                                                                    !canEditSearchShipment
                                                                                        ? false
                                                                                        : extractSearchShipment(
                                                                                              client.modulePermissionArray
                                                                                          )
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Search Shipment'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'ShipmentCreate',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canEditCreateShipment
                                                                                }
                                                                                checked={
                                                                                    !canEditCreateShipment
                                                                                        ? false
                                                                                        : extractCreateShipment(
                                                                                              client.modulePermissionArray
                                                                                          )
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Create Shipment'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Customs',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canEditSeeCustoms
                                                                                }
                                                                                checked={
                                                                                    !canEditSeeCustoms
                                                                                        ? false
                                                                                        : extractSeeCustoms(
                                                                                              client.modulePermissionArray
                                                                                          )
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Customs'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Accounting',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canEditSeeAccounting
                                                                                }
                                                                                checked={
                                                                                    !canEditSeeAccounting
                                                                                        ? false
                                                                                        : extractSeeAccounting(
                                                                                              client.modulePermissionArray
                                                                                          )
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Accounting'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Booking',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canEditSeeBookings
                                                                                }
                                                                                checked={
                                                                                    !canEditSeeBookings
                                                                                        ? false
                                                                                        : extractSeeBooking(
                                                                                              client.modulePermissionArray
                                                                                          )
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Bookings'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Order',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canEditSeeOrders
                                                                                }
                                                                                checked={extractSeeOrder(
                                                                                    client.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Orders'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'CreateOrder',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canEditCreateOrder
                                                                                }
                                                                                checked={extractCreateOrder(
                                                                                    client.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Create Order'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Container',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canEditSeeContainers
                                                                                }
                                                                                checked={extractSeeContainers(
                                                                                    client.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Containers'
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                justifyContent='flex-end'
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={1}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderSetUsersModuleAccessButton()}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>

                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel15'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel15'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Report Access
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {displayNoReports && (
                                                                        <div>
                                                                            No
                                                                            matching
                                                                            data
                                                                            found
                                                                        </div>
                                                                    )}
                                                                    {!displayNoReports &&
                                                                        reportAccessList?.map(
                                                                            report => (
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            onChange={event => {
                                                                                                this.handleReportChange(
                                                                                                    report.reportId,
                                                                                                    event
                                                                                                        .target
                                                                                                        .checked
                                                                                                )
                                                                                            }}
                                                                                            color="default"
                                                                                            name=""
                                                                                            checked={
                                                                                                report.isChecked
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        report.reportName
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel6'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel6'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Dashboard Access
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {displayNoDashboards && (
                                                                        <div>
                                                                            No
                                                                            matching
                                                                            data
                                                                            found
                                                                        </div>
                                                                    )}
                                                                    {!displayNoDashboards &&
                                                                        dashboardAccessList?.map(
                                                                            dashboard => (
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            onChange={event => {
                                                                                                this.handleDashboardChange(
                                                                                                    dashboard.dashboardId,
                                                                                                    event
                                                                                                        .target
                                                                                                        .checked
                                                                                                )
                                                                                            }}
                                                                                            color="default"
                                                                                            name=""
                                                                                            checked={
                                                                                                dashboard.isChecked
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        dashboard.dashboardName
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel7'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel7'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                View Options
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {
                                                                        <FormControl>
                                                                            <InputLabel>
                                                                                Client
                                                                                View
                                                                            </InputLabel>
                                                                            <Select
                                                                                name="clientView"
                                                                                value={
                                                                                    client?.clientViewId || ''
                                                                                }
                                                                                onChange={e =>
                                                                                    this.handleChange(
                                                                                        e,
                                                                                        'client-view',
                                                                                        false
                                                                                    )
                                                                                }>
                                                                                {
                                                                                    clientViews?.map(
                                                                                        (
                                                                                            view,
                                                                                            index
                                                                                        ) => (
                                                                                            <MenuItem
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                value={
                                                                                                    view.id
                                                                                                }>
                                                                                                {
                                                                                                    view.name
                                                                                                }
                                                                                            </MenuItem>
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>

                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel8'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel8'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Module Options
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    onChange={event => {
                                                                                        this.handleCheckboxChange(
                                                                                            event,
                                                                                            'usesOpenTrack'
                                                                                        )
                                                                                    }}
                                                                                    color="default"
                                                                                    name=""
                                                                                    checked={
                                                                                        client?.isOpenTrack || organization?.isOpenTrack || false
                                                                                    }
                                                                                    disabled={
                                                                                        organization?.isOpenTrack || false
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={
                                                                                `Use OpenTrack Container Data  ${organization?.isOpenTrack ? ' (Org Enabled)' : ''}`
                                                                            }
                                                                        />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    onChange={event => {
                                                                                        this.handleCheckboxChange(
                                                                                            event,
                                                                                            'usesOpenTrackTracking'
                                                                                        )
                                                                                    }}
                                                                                    color="default"
                                                                                    name=""
                                                                                    checked={
                                                                                        client?.isOpenTrackTracking || organization?.isOpenTrackTracking || false
                                                                                    }
                                                                                    disabled={
                                                                                        organization?.isOpenTrackTracking || false
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={
                                                                                `Use OpenTrack Tracking Link  ${organization?.isOpenTrackTracking ? ' (Org Enabled)' : ''}`
                                                                            }
                                                                        />
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div class="d-block text-right mt-4">
                                                    <Button
                                                        style={{
                                                            border: primarycolor,
                                                            color: primarycolor
                                                        }}
                                                        className="mr-3"
                                                        size="medium"
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={e =>
                                                            this.handleCancel()
                                                        }
                                                        disabled={
                                                            this.state.isPosting
                                                        }>
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            background: primarycolor,
                                                            color: textColor
                                                        }}
                                                        size="medium"
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={e =>
                                                            this.handleSubmit(e)
                                                        }
                                                        disabled={this.state.isPosting}>
                                                        Save
                                                    </Button>
                                                </div>
                                                <div class="d-block text-left mt-4">
                                                    {clientId !== null ? (
                                                        <NavLink
                                                            to={
                                                                usersPath.pathname +
                                                                '/' +
                                                                clientId
                                                            }>
                                                            <Button
                                                                className="align-self-center flex-grow-1"
                                                                variant="contained"
                                                                color="primary">
                                                                Manage Users
                                                            </Button>
                                                        </NavLink>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Paper>
                </CardContent>
            </div>
        )
    }

    async fetchClientData(id) {
        getClientData(
            id,
            data => {
                this.setState({ client: data, isLoading: false })
            },
            () => {
                this.setState({ isLoading: false })
            }
        )
    }

    async runCrawler() {
        const { clientId } = this.state

        let url = 'api/crawlers/RunCrawlerOnClient?clientId=' + clientId
        const token = await authService.getAccessToken()

        const response = await fetch(url, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            this.setState(prevState => {
                let alert = Object.assign({}, prevState.alert)
                alert.open = true
                alert.success = true
                alert.message = 'Crawler successfully run and data sync queued!'
                return { alert }
            })
        } else {
            this.setState(prevState => {
                let alert = Object.assign({}, prevState.alert)
                alert.open = true
                alert.success = false
                alert.message = 'Sorry, there was an error while triggering the crawler.'
                return { alert }
            })
        }

    }
    //reports & dashboards (get, save methods)

    //to get the client->reports
    async getClientReportsData(id) {
        const token = await authService.getAccessToken()
        const response = await fetch('api/bireport/GetBIReports/' + id, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            var data = await response.json()
            if (data === null) {
                data = []
            }
            this.setState({
                reportAccessList: data
            })

            if (data.length !== 0) {
                this.setState({
                    displayNoReports: false
                })
            }
        }

        this.setState({ isLoading: false })
    }
    handleACCChange = panel => (event, expanded) => {
        this.setState({ expanded: expanded ? panel : false })
    }

    //to get the client->dashboards
    async getClientDashboardsData(id) {
        const token = await authService.getAccessToken()
        const response = await fetch('api/bidashboard/GetBIDashboards/' + id, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
            
        if (response.ok) {
            var data = await response.json()
            if (data === null) {
                data = []
            }
            this.setState({
                dashboardAccessList: data
            })
            if (data.length !== 0) {
                this.setState({
                    displayNoDashboards: false
                })
            }
        }

        this.setState({ isLoading: false })
    }

    //to save the client->reports
    async saveClientReportsData() {
        const token = await authService.getAccessToken()
        const { reportAccessList } = this.state
        const { clientId } = this.state
        let list = []
        reportAccessList.map(x => {
            if (x.isChecked) {
                list.push({
                    ClientId: clientId,
                    ReportID: x.reportId,
                    ReportName: x.reportName
                })
            }
        })
        let data = {
            ClientId: clientId,
            Reports: list
        }
        let errText = ''
        await fetch('api/clients/SaveClientBIReports', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    // do nothing
                } else {
                    errText = 'Code[' + response.status + ']'

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                }
            })

            .then(d => {
                this.setState({
                    isPosting: false
                })
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                this.setState({
                    isPosting: false
                })
            })
    }
    //to save the client->dashboards
    async saveClientDashboardsData() {
        const token = await authService.getAccessToken()
        const { dashboardAccessList } = this.state
        const { clientId } = this.state
        let list = []
        dashboardAccessList.map(x => {
            if (x.isChecked) {
                list.push({
                    ClientId: clientId,
                    DashboardID: x.dashboardId,
                    DashboardName: x.dashboardName
                })
            }
        })
        let data = {
            ClientId: clientId,
            Dashboards: list
        }
        let errText = ''
        await fetch('api/clients/SaveClientBIDashboards', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    // do nothing
                } else {
                    errText = 'Code[' + response.status + ']'

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                }
            })

            .then(d => {
                this.setState({
                    isPosting: false
                })
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                this.setState({
                    isPosting: false
                })
            })
    }

    //reports & dashboards (get, save methods)

    async saveClientData(data, isNew) {
        this.setState({ isPosting: true })
        let errText = ''
        let url = isNew ? 'api/clients' : 'api/clients/' + data.id
        let _method = isNew ? 'POST' : 'PUT'
        const token = await authService.getAccessToken()
        await fetch(url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                let results = null
                if (response.ok) {
                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = true
                        alert.message = 'Client information is saved.'
                        return { alert }
                    })
                    results = response.json()
                } else {
                    if (response.status === 409) {
                        errText = `Client with company code ${data.integrationCompanyCode} already exists.`
                    } else {
                        errText = 'Code[' + response.status + ']'
                    }
                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                    results = response.text()
                }
                return results
            })

            .then(d => {
                this.setState({
                    client: d.id ? d : data,
                    clientId: d.id,
                    isPosting: false,
                    isValidForm: true
                })
                this.saveClientReportsData()
                this.saveClientDashboardsData()
                if (isNew) {
                    var path = clientsPath.pathname + '/' + d.id
                    this.props.history.push(path)
                }
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                this.setState({
                    isPosting: false,
                    isValidForm: false
                })
            })
    }
}
export default withStyles(styles)(ClientDetails)
